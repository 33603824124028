export default class TimeUtils {
  public static applyFastInput(value: string, key: string, selectionStart: number, selectionEnd: number): string {
    const expect = value + key;
    let actual = '';
    if (expect.length == 1) {
      if (/^([3-9])$/.test(expect)) {
        actual = `0${expect}:`;
      } else if (/^([0-2])$/.test(expect)) {
        actual = `${expect}`;
      }
    } else if (expect.length == 2) {
      if (/^(([0-2][0-4])|([0-1][0-9]))$/.test(expect)) {
        actual = `${expect}:`;
      }
    } else if (expect.length == 3 && /^([\:])$/.test(key)) {
      value = `${expect}:`;
    } else if (/^(([0-2][0-4])|([0-1][0-9]))$/.test(value) && /^([0-5])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `:${key}` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9])):[0-5]$/.test(value) && /^([0-9])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `${key}` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9])):$/.test(value) && /^([0-5])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `${key}` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9]))$/.test(value) && /^([6-9])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `:0${key}:` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9])):$/.test(value) && /^([6-9])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `0${key}:` +
        value.toString().substring(selectionEnd, value.length);
    } else if (expect.length == 5 && /^([\:])$/.test(key)) {
      value = `${expect}:`;
    } else if (/^(([0-2][0-4])|([0-1][0-9])):([0-5][0-9]):[0-5]$/.test(value) && /^([0-9])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `${key}` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9])):([0-5][0-9])$/.test(value) && /^([0-5])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `:${key}` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9])):([0-5][0-9]):$/.test(value) && /^([0-5])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `${key}` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9])):([0-5][0-9])$/.test(value) && /^([6-9])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `:0${key}` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9])):([0-5][0-9]):$/.test(value) && /^([6-9])$/.test(key)) {
      actual =
        value.toString().substring(0, selectionStart) +
        `0${key}` +
        value.toString().substring(selectionEnd, value.length);
    } else if (/^(([0-2][0-4])|([0-1][0-9])):([0-5][0-9]):([1-5][0-9])$/.test(value)) {
      actual = `${value}`;
    } else if (/^(([0-2][0-4])|([0-1][0-9])):([0-5][0-9]):([1-5][0-9])$/.test(expect)) {
      actual = `${expect}`;
    }

    return actual + '';
  }
}
