import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import _role, { Role } from '@/shared/model/role';
import OrganizationOwnerEditDialog from './organization-owner-edit/organization-owner-edit.vue';
import AuthorizationRoleEditDialog from './authorization-role-edit/authorization-role-edit.vue';
import roleRight, { RoleRight } from '@/shared/model/roleRight';
import { OdataItems } from '@/shared/model/OdataItems';
import { UserRight } from '@/shared/model/userRight';
import { RoleLoginTime } from '@/shared/model/roleLoginTime';
import TimeSpanUtils from '@/shared/utils/TimeSpanUtil';
import TimeUtils from '@/shared/utils/TimeUtils';

const name = 'authorization-roles-settings-view';
const logger = new Logger(name);
const folderModule = namespace('folder');
const roleModule = namespace('role');
const roleLoginTimeModule = namespace('roleLoginTime');
const userModule = namespace('user');
const organizationModule = namespace('organization');
const authModule = namespace('auth');
const organisationDocumentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
@Component({ name: name, components: { OrganizationOwnerEditDialog, AuthorizationRoleEditDialog } })
export default class AuthorizationRolesSettingsView extends Vue {
  currentTab = 0;
  @authModule.Getter('SettingsRolesAllowed')
  private SettingsRolesAllowed!: any;
  @authModule.Getter('roleId')
  private roleId!: any;
  @roleModule.Getter('getRoles')
  private roles!: OdataItems<Role>;
  @roleModule.Action('getRoles')
  private getRoles!: any;
  @roleModule.Action('deleteRole')
  private deleteRole!: any;
  @roleLoginTimeModule.Action('updateRoleLoginTime')
  private updateRoleLoginTime!: any;
  @organisationDocumentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;
  @folderModule.Action('getMenuFolders')
  private getMenuFolders!: any;
  @organizationModule.Action('getOrganisationUsers')
  private getUsers!: any;

  get roleHeaders() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  get timesHeaders() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [{ text: this.$t('group'), value: 'name' }];
    return headers;
  }

  authorizationRoleEditDialog: {
    isVisible: boolean;
    showDelete: boolean;
    model: {
      isCopyMode?: boolean;
      name: string;
      rights: RoleRight[];
      id: string;
    };
  } = {
    isVisible: false,
    showDelete: false,
    model: { name: '', rights: [], id: '' },
  };
  async authorizationRoleEditDialogOnClose() {
    this.authorizationRoleEditDialog.isVisible = false;
  }
  async authorizationRoleEditDialogOnUpdate(role: {
    name: string;
    rights: RoleRight[];
    id: string;
    folderRights: { [key: string]: string[] };
  }) {
    //TODO make one request for update role with user rights and role rights
    const errors: string[] = [];
    let roleId = role.id;
    //update role name if changed or add role if id is empty
    const roleNameChanged = this.authorizationRoleEditDialog.model.name != role.name;
    if (roleNameChanged || !role.id)
      await this.updateRole(_role.parse({ id: role.id, name: role.name, organisationId: this.organization.id }))
        .then((role: { id: string }) => {
          roleId = role.id;
          if (roleNameChanged) this.getUsers();
          this.getRoles();
        })
        .catch(() => {
          errors.push('error while creating|update role');
          roleId = '';
        });
    //if update (create) role finished correct then update rights
    if (roleId) {
      //update all rights
      const roleRights: any = [];
      Object.keys(role.folderRights).forEach((folderId) => {
        role.folderRights[folderId].forEach((element) => {
          roleRights.push(roleRight.toAPI({ folderId: folderId, name: element, roleId: roleId, allowed: true }));
        });
      });
      await this.updateRoleRights({
        roleId: roleId,
        rights: role.rights,
        folderRights: roleRights,
      });
      //#region make requests for folder for update folder rights in role
      // const promises: any[] = [];
      // Object.keys(role.folderRights).forEach((folderId) => {
      //   const roleRights: any = [];
      //   role.folderRights[folderId].forEach((element) => {
      //     roleRights.push(roleRight.toAPI({ folderId: folderId, name: element, roleId: roleId }));
      //   });
      //   promises.push(this.updateFoldersRoleRights({ folderId: folderId, roleId: roleId, rights: roleRights }));
      // });

      await this.getRoles({ searchParams: this.roles.searchParams });
      // await Promise.all(promises);
      //update your role rights if the edited role is the one in which the user is located
      if (roleId == this.account.profile.role_id) {
        await this.getMenuFolders();
        await this.signinSilent();
      }
    }
    //#endregion
    //get roles after update
    //hide dialog if no any errors
    this.authorizationRoleEditDialog.isVisible = errors.length > 0;
  }
  onClickRoleAdd(item: Role) {
    this.authorizationRoleEditDialog.model = { id: '', rights: [], name: '' };
    this.authorizationRoleEditDialog.isVisible = true;
  }
  onClickRoleRow(item: Role) {
    this.roleEdit(item);
  }
  onClicRoleEdit(item: Role) {
    this.roleEdit(item);
  }
  onClicRoleDelete(item: Role) {
    this.$confirm
      .open(
        `${this.$t(`dialogs__confirmation__delete__title`)}`,
        `${this.$t('dialogs__confirmation__delete__message')}`,
        {
          cancelText: this.$t('cancel'),
          okText: this.$t('delete'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.deleteRole(item.id);
          await this.getRoles({ searchParams: this.roles.searchParams });
        }
      });
  }
  async onClicRoleCopy(item: Role) {
    await this.getRoleRights({ roleId: item.id });
    this.authorizationRoleEditDialog.model.name = item.name;
    this.authorizationRoleEditDialog.model.id = item.id;
    this.authorizationRoleEditDialog.model.rights = this.roleRights.items;
    this.authorizationRoleEditDialog.model.isCopyMode = true;
    this.authorizationRoleEditDialog.isVisible = true;
  }

  async roleEdit(item: Role) {
    //request rights for role before edit
    await this.getRoleRights({ roleId: item.id });
    this.authorizationRoleEditDialog.model.name = item.name;
    this.authorizationRoleEditDialog.model.id = item.id;
    this.authorizationRoleEditDialog.model.rights = this.roleRights.items;
    this.authorizationRoleEditDialog.model.isCopyMode = false;
    this.authorizationRoleEditDialog.isVisible = true;
  }

  async onClickOrganizationOwner() {
    const userId = this.account.profile.sub;
    await Promise.all([this.getUserRights({ userId }), this.getOrganisationDocumentExtraStatusSettings()]);

    this.organizationOwnerEditDialog.model = { id: userId, rights: this.userRights.items };
    this.organizationOwnerEditDialog.isVisible = true;
  }

  @roleModule.Getter('getRoleRights')
  private roleRights!: any;
  @roleModule.Action('getRoleRights')
  private getRoleRights!: any;
  @roleModule.Action('updateRoleRights')
  private updateRoleRights!: any;
  @roleModule.Getter('getInProgressUpdateRoleRights')
  private inProgressUpdateRoleRights!: boolean;
  @roleModule.Getter('getInProgressUpdateRole')
  private inProgressUpdateRole!: boolean;
  @folderModule.Action('updateFoldersRoleRights')
  private updateFoldersRoleRights!: any;
  @folderModule.Getter('getInProgressUpdateFoldersRoleRights')
  private inProgressUpdateFoldersRoleRights!: any;
  @organizationModule.Getter('getOrganization')
  private organization!: any;
  @roleModule.Action('updateRole')
  private updateRole!: any;
  @userModule.Getter('getUserRights')
  private userRights!: OdataItems<UserRight>;
  @userModule.Action('getUserRights')
  private getUserRights!: any;
  @userModule.Action('updateUserRights')
  private updateUserRights!: any;
  @userModule.Getter('getUpdateUserRightsIsLoading')
  private updateUserRightsIsLoading!: any;
  @authModule.Getter('getAccount')
  private account!: any;
  @authModule.Getter('isOwner')
  private isOwner!: any;

  organizationOwnerEditDialog: {
    isVisible: boolean;
    showDelete: boolean;
    model: { id: string; rights: UserRight[] };
  } = {
    isVisible: false,
    showDelete: false,
    model: { id: '', rights: [] },
  };
  async organizationOwnerEditDialogOnClose() {
    this.organizationOwnerEditDialog.isVisible = false;
  }
  @authModule.Action('signinSilent')
  private signinSilent!: any;

  async organizationOwnerEditDialogOnUpdate(owner: { rights: RoleRight[]; id: string }) {
    const ownerId = owner.id;
    //update all rights
    await this.updateUserRights({ userId: ownerId, rights: owner.rights });
    await this.signinSilent();
    this.organizationOwnerEditDialog.isVisible = false;
  }
  //implement custom sorting (same as standart)
  clickSortOrder(options: any) {
    if (options.sortDesc[0] == true) {
      options.sortDesc.pop();
      return;
    }
    if (options.sortDesc[0] == false) {
      options.sortDesc[0] = true;
      return;
    } else options.sortDesc = [true];
  }
  onMenuFromClickSeconds(event: any, roleLoginTime: RoleLoginTime) {
    const component = this.$refs[`menu-from-time-${roleLoginTime.roleId}-${roleLoginTime.weekDay}`] as any;
    this.updateRoleLoginTime(roleLoginTime);
    component[0].save(roleLoginTime.fromTime);
    if (!roleLoginTime.id) this.getRoles();
  }
  onMenuToClickSeconds(event: any, roleLoginTime: RoleLoginTime) {
    const component = this.$refs[`menu-to-time-${roleLoginTime.roleId}-${roleLoginTime.weekDay}`] as any;
    this.updateRoleLoginTime(roleLoginTime);
    component[0].save(roleLoginTime.toTime);
    if (!roleLoginTime.id) this.getRoles();
  }
  onClickAllowed(roleLoginTime: RoleLoginTime) {
    this.updateRoleLoginTime(roleLoginTime);

    if (!roleLoginTime.id) this.getRoles();
  }
  async onBlurFromTime(event: any, item: RoleLoginTime) {
    if (this.isValid(`from-time-${item.roleId}-${item.weekDay}`)) {
      if (event.target.value != item.fromTime) {
        item.fromTime = event.target.value;
        this.updateRoleLoginTime(item);
      }
    }
  }

  onKeypressFromToTime(evt: any, item: RoleLoginTime) {
    let expect = '';
    if (
      evt.target.selectionEnd > 0 ||
      (evt.target.selectionStart > 0 && evt.target.selectionEnd != evt.target.selectionStart)
    ) {
      evt.target.value =
        evt.target.value.toString().substring(0, evt.target.selectionStart) +
        evt.target.value.toString().substring(evt.target.selectionEnd, evt.target.value.length);
    }

    expect = TimeUtils.applyFastInput(evt.target.value, evt.key, evt.target.selectionStart, evt.target.selectionEnd);

    if (expect.length == 8) {
      return true;
    }
    evt.target.value = `${expect}`;
    evt.target.selectionStart = expect.length;
    evt.target.selectionEnd = expect.length;
    evt.preventDefault();
  }

  async onBlurToTime(event: any, item: RoleLoginTime) {
    if (this.isValid(`to-time-${item.roleId}-${item.weekDay}`)) {
      if (event.target.value != item.toTime) {
        item.toTime = event.target.value;
        this.updateRoleLoginTime(item);
      }
    }
  }

  isValid(refName: string) {
    const vueComponent = this.$refs[refName];
    const isValueValidResult = Array.isArray(vueComponent)
      ? (vueComponent as any[])[0].valid
      : (vueComponent as any).valid;
    return isValueValidResult;
  }
  get fromTimeRules() {
    return [
      (v: string) => {
        return TimeSpanUtils.Validation(v) || this.$i18n.t('messages.timespan_not_valid');
      },
    ];
  }
  get toTimeRules() {
    return [
      (v: string) => {
        return TimeSpanUtils.Validation(v) || this.$i18n.t('messages.timespan_not_valid');
      },
    ];
  }
}
