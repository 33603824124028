import { render, staticRenderFns } from "./authorization-role-edit.html?vue&type=template&id=cb4ed8f8&scoped=true&external"
import script from "./authorization-role-edit.ts?vue&type=script&lang=ts&external"
export * from "./authorization-role-edit.ts?vue&type=script&lang=ts&external"
import style0 from "./authorization-role-edit.scss?vue&type=style&index=0&id=cb4ed8f8&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb4ed8f8",
  null
  
)

/* custom blocks */
import block0 from "./authorization-role-edit.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Femployees-roles%2Fauthorization-roles%2Fauthorization-role-edit%2Fauthorization-role-edit.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCheckbox,VCol,VDialog,VDivider,VForm,VIcon,VImg,VLabel,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle,VRow,VSheet,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTextField,VToolbar,VTreeview})
