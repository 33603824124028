import RouterUtils from '@/shared/utils/RouterUtils';
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import ownerrigh, { Right } from '@/shared/model/right';
import { Folder } from '@/shared/model/folder';
import roleRight, { RoleRight } from '@/shared/model/roleRight';
import { OrganisationDocumentExtraStatusSetting } from '@/shared/model/organisationDocumentExtraStatusSetting';
import { stat } from 'fs';

const name = 'authorization-role-edit-dialog';
const logger = new Logger(name);

const documentStatusModule = namespace('documentStatus');
const folderModule = namespace('folder');
const organizationModule = namespace('organization');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');

@Component({ name: name })
export default class AuthorizationRoleEditDialog extends Vue {
  @Ref('authorization-role-edit-form')
  private refAuthorizationEditForm!: any;
  @organizationModule.Getter('getOrganization')
  private organization!: any;
  currentTab = 0;
  settingRightsModel: RoleRight[] = [];
  documentsRightsModel: RoleRight[] = [];
  documentsStatusValuesRightsModel: RoleRight[] = [];
  get settingRights() {
    return this.settingRightsModel;
  }
  get documentsRights() {
    return this.documentsRightsModel;
  }
  get documentsStatusValuesRights() {
    return this.documentsStatusValuesRightsModel;
  }
  folderRights: string[] = [];
  selectedRight: {
    ReadStatus: string[];
    WriteStatus: string[];
    UploadStatus: string[];
  } = { ReadStatus: [], WriteStatus: [], UploadStatus: [] };
  @PropSync('visibility', { default: false })
  private dialog!: boolean;
  @Prop({ default: false })
  private isLoading!: boolean;
  @Watch('dialog')
  async onUpdateDialog(newV: any, oldV: any) {
    if (newV) {
      const modelId = this.model.id;
      if (this.model.isCopyMode) {
        this.model.name = this.model.name + this.$t('copy');
        this.model.id = '';
        this.model.rights.forEach((element, i) => {
          this.model.rights[i].id = '';
          this.model.rights[i].roleId = '';
        });
      }
      if (this.refAuthorizationEditForm) this.refAuthorizationEditForm.resetValidation();
      this.settingRightsModel = Object.assign(
        [],
        [
          roleRight.parse({ claimType: 'SettingsOrganisations', roleId: this.model.id }),
          roleRight.parse({ claimType: 'SettingsMembers', roleId: this.model.id }),
          roleRight.parse({ claimType: 'SettingsRoles', roleId: this.model.id }),
          roleRight.parse({ claimType: 'SettingsAllocations', roleId: this.model.id }),
          roleRight.parse({ claimType: 'SettingsFolders', roleId: this.model.id }),
          roleRight.parse({ claimType: 'SettingsDocuments', roleId: this.model.id }),
          roleRight.parse({ claimType: 'SettingsActionLogs', roleId: this.model.id }),
          roleRight.parse({ claimType: 'ViewAddresses', roleId: this.model.id }),
          roleRight.parse({ claimType: 'EditAddresses', roleId: this.model.id }),
          roleRight.parse({ claimType: 'SettingsProcesses', roleId: this.model.id }),
          roleRight.parse({ claimType: 'ViewAllProcessLists', roleId: this.model.id }),
          roleRight.parse({ claimType: 'ViewProcessLists', roleId: this.model.id }),
          roleRight.parse({ claimType: 'EditProcessLists', roleId: this.model.id }),
          roleRight.parse({ claimType: 'DeleteProcessLists', roleId: this.model.id }),
        ]
      );
      for (const element of this.model.rights) {
        if (this.settingRightsModel.filter((x) => x.sName == element.sName)[0]) {
          this.settingRightsModel.filter((x) => x.sName == element.sName)[0].allowed = element.allowed;
          this.settingRightsModel.filter((x) => x.sName == element.sName)[0].id = element.id;
        }
      }

      this.documentsRightsModel = Object.assign(
        [],
        [
          roleRight.parse({ claimType: 'UploadDocuments', roleId: this.model.id }),
          roleRight.parse({ claimType: 'DeleteDocuments', roleId: this.model.id }),
          roleRight.parse({ claimType: 'DeleteDocumentsFromTrash', roleId: this.model.id }),
          roleRight.parse({ claimType: 'DocumentShowOnlyIdSearch', roleId: this.model.id }),
          roleRight.parse({ claimType: 'EditDocumentInformations', roleId: this.model.id }),
          roleRight.parse({ claimType: 'MergeDocuments', roleId: this.model.id }),
          roleRight.parse({ claimType: 'CheckDocumentGobd', roleId: this.model.id }),
          roleRight.parse({ claimType: 'EditDocumentAllocations', roleId: this.model.id }),
          roleRight.parse({ claimType: 'ExportDocumentAllocations', roleId: this.model.id }),
          roleRight.parse({ claimType: 'ViewDocumentComments', roleId: this.model.id }),
          roleRight.parse({ claimType: 'ViewAllDocumentThreads', roleId: this.model.id }),
          roleRight.parse({ claimType: 'CloseAllDocumentThreads', roleId: this.model.id }),
        ]
      );
      for (let index = 0; index < this.model.rights.length; index++) {
        const element = this.model.rights[index];
        if (this.documentsRightsModel.filter((x) => x.sName == element.sName)[0]) {
          this.documentsRightsModel.filter((x) => x.sName == element.sName)[0].allowed = element.allowed;
          this.documentsRightsModel.filter((x) => x.sName == element.sName)[0].id = element.id;
        }
      }
      this.documentsStatusValuesRightsModel = Object.assign(
        [],
        [
          roleRight.parse({ claimType: 'payed_View', roleId: this.model.id }),
          roleRight.parse({ claimType: 'payed_Write', roleId: this.model.id }),
          roleRight.parse({ claimType: 'payed_DocumentView', roleId: this.model.id }),
          roleRight.parse({ claimType: 'accepted_View', roleId: this.model.id }),
          roleRight.parse({ claimType: 'accepted_Write', roleId: this.model.id }),
          roleRight.parse({ claimType: 'accepted_DocumentView', roleId: this.model.id }),
          roleRight.parse({ claimType: 'final_check_View', roleId: this.model.id }),
          roleRight.parse({ claimType: 'final_check_Write', roleId: this.model.id }),
          roleRight.parse({ claimType: 'final_check_DocumentView', roleId: this.model.id }),
        ]
      );
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach((x) => {
        this.documentsStatusValuesRightsModel.push(
          roleRight.parse({ claimType: `extra${x}_View`, roleId: this.model.id })
        );
        this.documentsStatusValuesRightsModel.push(
          roleRight.parse({ claimType: `extra${x}_Write`, roleId: this.model.id })
        );
        this.documentsStatusValuesRightsModel.push(
          roleRight.parse({ claimType: `extra${x}_DocumentView`, roleId: this.model.id })
        );
      });

      this.model.rights.forEach((element) => {
        const index2 = this.documentsStatusValuesRightsModel.findIndex((x) => x.sName == element.sName);
        if (index2 >= 0) {
          this.documentsStatusValuesRightsModel[index2].allowed = element.allowed;
          this.documentsStatusValuesRightsModel[index2].id = element.id;
        }
      });

      this.settings.documentsRights = this.documentsRights.filter((x) => x.allowed);
      this.settings.documentsStatusValuesRights = this.documentsStatusValuesRights.filter((x) => x.allowed);
      this.settings.settingsRights = this.settingRights.filter((x) => x.allowed);

      this.modelName = this.model.name;
      this.updated = {};
      let roleId = this.model.id == '' || this.model.id === undefined ? RouterUtils.emptyGuid : this.model.id;
      if (this.model.isCopyMode) roleId = modelId;
      await this.getFolders({ roleId: roleId });
      // if (this.model.isCopyMode) {
      this.foldersFlat.forEach((folder, i) => {
        this.updated[folder.id] = folder.roleRights;
      });
      // }
    }
  }
  @folderModule.Action('getFoldersAll')
  private getFolders!: any;
  @PropSync('value', {
    default: () => {
      [];
      [];
      [];
    },
  })
  private model!: {
    name: string;
    rights: RoleRight[];
    id: string;
    isCopyMode?: boolean;
  };
  @Watch('value', { deep: true })
  onUpdateValue(newV: { name: string; rights: [] }, oldV: { name: string; rights: [] }) {
    this.modelName = newV.name;
    // this.updated = newV.rights;
  }
  private settings: {
    settingsRights: RoleRight[];
    documentsRights: RoleRight[];
    documentsStatusValuesRights: RoleRight[];
    folderRights: RoleRight[];
  } = { settingsRights: [], documentsRights: [], documentsStatusValuesRights: [], folderRights: [] };
  modelName = '';
  async onClickedUpdate() {
    const isFormValidResult = await this.refAuthorizationEditForm.validate();
    this.$emit('click:update', {
      name: this.modelName,
      id: this.model.id,
      rights: this.settingRights
        .concat(this.documentsRights)
        .concat(this.documentsStatusValuesRights)
        // pass for save  only changed or new rights
        .filter((x) => x.id || x.allowed == false),
      folderRights: this.updated,
    });
  }

  onClickedClose() {
    this.$emit('click:close');
  }

  updateSelectAllSettingsRights(nVal?: boolean, oVal?: boolean) {
    if (nVal) {
      this.settingRights.forEach((x: any) => {
        if (this.settings.settingsRights.indexOf(x.id) < 0) {
          x.allowed = true;
          this.settings.settingsRights.push(x);
        }
      });
    } else {
      this.settings.settingsRights = [];
      this.settingRights.forEach((y: any) => {
        y.allowed = false;
      });
    }
  }
  get indeterminateSettingsRights(): boolean {
    return this.settings.settingsRights.length > 0 && this.settings.settingsRights.length < this.settingRights.length;
  }
  get inputValueSelecteSettingsRights(): boolean {
    return this.settings.settingsRights.length == this.settingRights.length;
  }
  updateSelectAllDocumentsRights(nVal?: boolean, oVal?: boolean) {
    if (nVal) {
      this.documentsRights.forEach((x: any) => {
        if (this.settings.documentsRights.indexOf(x.id) < 0) {
          x.allowed = true;
          this.settings.documentsRights.push(x);
        }
      });
    } else {
      this.settings.documentsRights = [];
      this.documentsRights.forEach((y: any) => {
        y.allowed = false;
      });
    }
  }
  get indeterminateDocumentsRights(): boolean {
    return (
      this.settings.documentsRights.length > 0 && this.settings.documentsRights.length < this.documentsRights.length
    );
  }
  get inputValueSelecteDocumentsRights(): boolean {
    return this.settings.documentsRights.length == this.documentsRights.length;
  }
  updateSelectAllDocumentsStatusValuesRights(nVal?: boolean, oVal?: boolean) {
    if (nVal) {
      this.documentsStatusValuesRights.forEach((x: any) => {
        if (this.settings.documentsStatusValuesRights.indexOf(x.id) < 0) {
          x.allowed = true;
          this.settings.documentsStatusValuesRights.push(x);
        }
      });
    } else {
      this.settings.documentsStatusValuesRights = [];
      this.documentsStatusValuesRights.forEach((y: any) => {
        y.allowed = false;
      });
    }
  }
  get indeterminateDocumentsStatusValuesRights(): boolean {
    return (
      this.settings.documentsStatusValuesRights.length > 0 &&
      this.settings.documentsStatusValuesRights.length < this.documentsStatusValuesRights.length
    );
  }
  get inputValueSelecteDocumentsStatusValuesRights(): boolean {
    return this.settings.documentsStatusValuesRights.length == this.documentsStatusValuesRights.length;
  }

  @folderModule.Getter('getFoldersAllTreeViewFlat')
  private foldersFlat!: Folder[];

  updateWithChildren(folder: any, right: 'View' | 'Write' | 'Upload', add: boolean) {
    if (Array.isArray(folder.children))
      folder.children.forEach((element: any) => {
        this.updateWithChildren(element, right, add);
      });
    if (folder.roleRights.filter((x: string) => x.endsWith(right)).length > 0) {
      if (!add) {
        const index = folder.roleRights.indexOf(`Folder_${folder.id}_${right}`, 0);
        if (index > -1) {
          folder.roleRights.splice(index, 1);
          this.update(folder.id, folder.roleRights);
        }
      }
    } else if (add) {
      folder.roleRights.push(`Folder_${folder.id}_${right}`);
      this.update(folder.id, folder.roleRights);
    }
  }

  checkWithChildren(folder: any, right: 'View' | 'Write' | 'Upload'): number {
    let count = 0;
    if (Array.isArray(folder.children))
      folder.children.forEach((element: any) => {
        count += this.checkWithChildren(element, right);
      });
    if (folder.roleRights.filter((x: string) => x.endsWith(right)).length > 0) {
      count += 1;
    }
    return count;
  }

  updateSelectAllFolderTreeRights(nVal: boolean, right: 'View' | 'Write' | 'Upload') {
    if (nVal) {
      this.folders.forEach((x: any) => {
        this.updateWithChildren(x, right, true);
      });
    } else {
      this.folders.forEach((x: any) => {
        this.updateWithChildren(x, right, false);
      });
    }
  }

  indeterminateFolderTreeRights(right: 'View' | 'Write' | 'Upload'): boolean {
    let allChecked = 0;
    this.folders.forEach((x: any) => {
      allChecked += this.checkWithChildren(x, right);
    });
    return allChecked > 0 && allChecked < this.foldersFlat.length;
  }

  inputValueSelectedFolderTreeRights(right: 'View' | 'Write' | 'Upload'): boolean {
    let allChecked = 0;
    this.folders.forEach((x: any) => {
      allChecked += this.checkWithChildren(x, right);
    });
    return allChecked == this.foldersFlat.length;
  }

  get indeterminateFolderTreeUploadRights(): boolean {
    return this.indeterminateFolderTreeRights('Upload');
  }
  get inputValueSelecteFolderTreeUploadRights(): boolean {
    return this.inputValueSelectedFolderTreeRights('Upload');
  }
  get indeterminateFolderTreeWriteRights(): boolean {
    return this.indeterminateFolderTreeRights('Write');
  }
  get inputValueSelecteFolderTreeWriteRights(): boolean {
    return this.inputValueSelectedFolderTreeRights('Write');
  }
  get indeterminateFolderTreeViewRights(): boolean {
    return this.indeterminateFolderTreeRights('View');
  }
  get inputValueSelecteFolderTreeViewRights(): boolean {
    return this.inputValueSelectedFolderTreeRights('View');
  }
  @folderModule.Getter('getFoldersAllTreeView')
  private folders!: {
    folder: Folder;
    id: string;
    name: string;
    parent: { name: string; id: string };
    count: undefined;
    children: any[];
  }[];
  private open: any[] = [];
  @Ref('documentFolderTree')
  private refDocumentFolderTree!: any;
  private toggleOpenForNode(open: boolean, treeNodeId: string) {
    open = !open; // recreate `update:open` behavior for Treeview (https://github.com/vuetifyjs/vuetify/blob/8bb752b210d25fbebcea12cd073d2ce4986f5e12/packages/vuetify/src/components/VTreeview/VTreeviewNode.ts#L180)

    this.refDocumentFolderTree.updateOpen(treeNodeId, open); // `REF` returns arrya since used within the `v-for` directive (https://forum.vuejs.org/t/this-refs-theid-returns-an-array/31995/9)
  }

  toggle(x: RoleRight) {
    x.allowed = !x.allowed;
  }
  private updated: { [key: string]: string[] } = {};
  update(folderId: string, roleRightNames: string[]) {
    this.updated[folderId] = roleRightNames;
  }
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private organisationDocumentExtraStatusSettings!: any;

  private getTranslation(item: string) {
    const valueKey = item.replace('_View', '').replace('_Write', '').replace('_DocumentView', '');
    const statusName = this.organisationDocumentExtraStatusSettings.items.filter(
      (x: OrganisationDocumentExtraStatusSetting) => x.statusValue == valueKey
    )[0]?.name;

    return this.$t('documents_status_values.' + item, { 0: '"' + statusName + '"' });
  }
  get roleNameRules() {
    return [(v: any) => !!v.toString().trim() || this.$i18n.t('role_name_required') + ''];
  }
}
