import { Role } from '@/shared/model/role';
import { OdataItems } from '@/shared/model/OdataItems';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import user, { User } from '@/shared/model/user';
import RouterUtils from '@/shared/utils/RouterUtils';
import { UserType } from '@/shared/backend/userType';
import GeneralUtils from '@/shared/utils/generalUtils';
import organisationUser, { OrganisationUser } from '@/shared/model/organisationUser';

const name = 'employee-edit-dialog';
const logger = new Logger(name);
const roleModule = namespace('role');
const authModule = namespace('auth');
const organizationModule = namespace('organization');
@Component({ name: name })
export default class EmployeeEditDialog extends Vue {
  @Ref('EditEmployeeForm')
  private refEditEmployeeForm!: any;
  @roleModule.Getter('getRoles')
  private roles!: OdataItems<Role>;
  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: false })
  private ShowDelete!: boolean;
  @authModule.Getter('getAccount')
  private account!: any;

  @Prop({ default: () => user.parse({}) })
  private value!: OrganisationUser;
  @organizationModule.Getter('isOrganisationUsersOwnerForCurrentOrganisation')
  private isOwnerForCurrent!: (x: OrganisationUser) => boolean;

  get rules() {
    return {
      required: (value: any) => !!value || this.$t('required'),
      short_form_maxlength: (value: any) => value.length <= 16 || this.$t('short_form_maxlength'),
      email: (v: string) => this.emailValidation(v) || this.$i18n.t('email_required'),
    };
  }

  emailValidation(v: string) {
    return (
      v &&
      v.trim().length >= 6 &&
      v.trim().length < 256 && // with domain minimum 6 chars (https://stackoverflow.com/a/9034077)
      /^[^@]+@[^@]{2,}\.[^@]{2,}$/.test(v)
    ); // simple validation because of https://stackoverflow.com/a/202528 (possible typos and made-up emails)
  }

  get showFutureFeature() {
    return GeneralUtils.showFutureFeature;
  }

  @Watch('value')
  onUpdateValue(newV: OrganisationUser, oldV: OrganisationUser) {
    this.employee = Object.assign(this.employee, newV);

    //reset validation before show dialog
    if (newV) this.resetValidation();
  }
  private employee: OrganisationUser = organisationUser.parse({});
  resetValidation() {
    if (this.refEditEmployeeForm) this.refEditEmployeeForm.resetValidation();
  }
  onClickedDelete() {
    this.$emit('click:delete', this.employee);
  }
  async onClickedUpdate() {
    const isFormValidResult = await this.refEditEmployeeForm.validate();

    if (!isFormValidResult) return;

    this.$emit('click:update', this.employee);
  }

  onClickedClose() {
    this.$emit('click:close');
  }

  isOwner(user: OrganisationUser) {
    return this.isOwnerForCurrent(user);
  }
}
