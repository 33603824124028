import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import userRight, { UserRight } from '@/shared/model/userRight';

const name = 'organization-owner-edit-dialog';
const logger = new Logger(name);

const authModule = namespace('auth');
const organisationDocumentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');

@Component({ name: name })
export default class OrganizationOwnerEditDialog extends Vue {
  @authModule.Getter('getAccount')
  private account!: any;
  @Prop({ default: false })
  private isLoading!: boolean;
  @Prop({ default: false })
  private dialog!: boolean;
  @Watch('dialog')
  onUpdateDialog(newV: any, oldV: any) {
    if (newV) {
      this.userRights = Object.assign([], this.owner.rights);
      this.selected = this.userRights.filter((x) => x.allowed);
    }
  }
  userRights: UserRight[] = [];
  selectAll?: boolean = true;
  @organisationDocumentExtraStatusSettingModule.Getter('getNameByValue')
  private getNameByValue!: (s: string) => {};

  private checkSelectAll() {
    let result = true;
    this.userRights.forEach((x: UserRight) => {
      if (x.allowed == false) result = false;
    });
    this.selectAll = result;
  }

  updateSelectAll(nVal?: boolean, oVal?: boolean) {
    if (this.selectAll == true) {
      this.userRights.forEach((x: UserRight) => {
        if (this.selected.indexOf(x.id) < 0) {
          x.allowed = true;
          this.selected.push(x);
        }
      });
      return;
    }
    if (this.selectAll == false) {
      this.selected = [];
      this.userRights.forEach((y: UserRight) => {
        y.allowed = false;
      });
    }
  }

  @PropSync('value', {
    default: () => {
      [];
      ('');
      ('');
    },
  })
  private owner!: {
    rights: UserRight[];
    id: string;
  };

  @Watch('value')
  onUpdateValue(newV: any, oldV: any) {
    this.owner.rights = Object.assign(this.owner.rights, newV);
  }
  private selected: any[] = [];
  onClickedUpdate() {
    this.$emit('click:update', {
      id: this.owner.id,
      rights: this.userRights.filter((x) => x.id || x.allowed == false),
    });
  }
  toggle(x: UserRight) {
    x.allowed = !x.allowed;
    this.checkSelectAll();
  }
  onClickedClose() {
    this.$emit('click:close');
  }
}
