import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import EmployeesSettingsView from './employees/employees.vue';
import AuthorizationRolesSettingsView from './authorization-roles/authorization-roles.vue';
import { namespace } from 'vuex-class';

const name = 'employees-roles-settings-view';
const logger = new Logger(name);
const roleModule = namespace('role');
const userModule = namespace('user');
const organizationModule = namespace('organization');

@Component({ name: name, components: { EmployeesSettingsView, AuthorizationRolesSettingsView } })
export default class EmployeesRolesSettingsView extends Vue {
  @roleModule.Getter('getRoles')
  private roles!: any;
  @roleModule.Action('getRoles')
  private getRoles!: any;
  @organizationModule.Getter('getOrganisationUsers')
  private users!: any;
  @organizationModule.Action('getOrganisationUsers')
  private getUsers!: any;
  @Watch('roles.searchParams', { deep: true })
  onUpdateRolesSearchParams(nwal: any, owal: any) {
    this.getRoles();
  }
  @Watch('users.searchParams', { deep: true })
  onUpdateUsersSearchParams(nwal: any, owal: any) {
    this.getUsers();
  }
  mounted() {}
}
