import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import EmployeeEditDialog from './employee-edit/employee-edit.vue';
import { Role } from '@/shared/model/role';
import { OdataItems } from '@/shared/model/OdataItems';
import { UserType } from '@/shared/backend/userType';
import { EmailData, EmailType } from '@/shared/model/smallPayloadModels/emailData';
import { Organisation } from '@/shared/model/organisation';
import organisationUser, { OrganisationUser } from '@/shared/model/organisationUser';

const name = 'users-settings-view';
const logger = new Logger(name);
const authModule = namespace('auth');
const userModule = namespace('user');
const roleModule = namespace('role');
const organizationModule = namespace('organization');

@Component({ name: name, components: { EmployeeEditDialog } })
export default class UsersSettingsView extends Vue {
  @authModule.Getter('SettingsMembersAllowed')
  private SettingsMembersAllowed!: boolean;
  @authModule.Getter('getAccount')
  private account!: any;
  @authModule.Getter('isOwner')
  private isOwner!: boolean;
  @roleModule.Getter('getRoles')
  private roles!: OdataItems<Role>;
  @organizationModule.Getter('getOrganisationUsers')
  private users!: OdataItems<OrganisationUser>;
  @organizationModule.Getter('isOrganisationUsersOwnerForCurrentOrganisation')
  private isOwnerForCurrent!: (x: OrganisationUser) => boolean;
  @authModule.Action('signinRedirect')
  private signinRedirect!: any;
  @userModule.Action('sendEmail')
  private actionSendEmail!: any;
  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('email'), value: 'email' },
      { text: this.$t('short_form'), value: 'shortName' },
      { text: this.$t('first_name'), value: 'firstName' },
      { text: this.$t('last_name'), value: 'lastName' },
      { text: this.$t('group'), value: 'roleName' },
      { text: this.$t('personal_number'), value: 'employeeNumber' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  @userModule.Getter('getUser')
  private user!: any;
  @organizationModule.Action('getOrganisationUsers')
  private getUsers!: any;
  @organizationModule.Action('updateOrganisationUser')
  private actionUpdateUser!: any;
  @userModule.Action('moveToRecycle')
  private moveToRecycle!: any;

  userEditDialog = {
    show: false,
    showDelete: false,
    model: { ...organisationUser.parse({}) },
  };
  async userEditDialogOnClose(user: OrganisationUser) {
    this.userEditDialog.show = false;
  }
  async userEditDialogOnDelete(user: OrganisationUser) {
    this.userEditDialog.show = false;
    this.$confirm
      .open(`${this.$t('delete_dialog_title')}`, `${this.$t('delete_dialog_text')}`, {
        cancelText: this.$t('delete_dialog_cancel'),
        okText: this.$t('delete_dialog_ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.moveToRecycle(user.userId).then(() => {
            this.getUsers();
          });
        }
      });
  }

  async userEditDialogOnUpdate(user: OrganisationUser, userRoleId: string) {
    this.userEditDialog.show = false;

    await this.actionUpdateUser(user).then(async ({ id }: any) => {
      await this.getUsers();
      if (!user.id) {
        await this.sendEmailOnAddEmployee(user);
      }
      if (
        !this.isOwner &&
        (this.userEditDialog.model?.roleId !== user.roleId || this.userEditDialog.model?.isSeller !== user.isSeller)
      ) {
        await this.signinRedirect();
      }
    });
  }

  private async sendEmailOnAddEmployee(user: OrganisationUser) {
    const payload: EmailData = {
      userEmail: user.email,
      phone: '',
      organizationName: this.organization.name,
      type: EmailType.addEmployee,
      siteHost: window.location.host,
    };
    await this.actionSendEmail(payload);
  }

  onAdd() {
    this.add();
  }
  add() {
    this.userEditDialog.model = { ...organisationUser.parse({}) };
    this.userEditDialog.model.isSeller = true;
    this.userEditDialog.showDelete = false;
    this.userEditDialog.show = true;
  }
  edit(user: OrganisationUser) {
    this.userEditDialog.model = { ...user };
    this.userEditDialog.showDelete = true;
    this.userEditDialog.show = true;
  }
  onClicEmployeeEdit(item: OrganisationUser) {
    this.edit(item);
  }
  getRoleName(item: OrganisationUser) {
    return item.roleName;
  }

  getKurzNameForOwner(item: OrganisationUser) {
    return item.shortName;
  }

  getFirstNameForOwner(item: OrganisationUser) {
    return item.firstName;
  }

  getLastNameForOwner(item: OrganisationUser) {
    return item.lastName;
  }
}
